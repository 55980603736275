// xuyangweiconfig

export default {
  // 填自己的
  QQ_APP_ID: "",
  QQ_REDIRECT_URI: "",
  WEIBO_APP_ID: "",
  WEIBO_REDIRECT_URI: "",
  TENCENT_CAPTCHA: "2088053498",
  UPLOAD_SIZE: 600 // 压缩文件大小
};
