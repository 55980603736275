<template>
  <div>
    <el-dialog title="网站" :visible.sync="searchFlag" width="50%" :modal="false" center class="custom-dialog">
      <div class="search-result-wrapper" style=" background: rgb(0,0,0,0.5);">
        <hr class="divider" />
        <br />
        <br />
        <div class="link">
          <!--第一组-->
            <div class="row">
              <div class="col">
                <a id="link-url-2" href="https://www.runoob.com/redis/redis-tutorial.html" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-2">redis</span>
                  </div>
                </a>
              </div>
              <div class="col">
                <a id="link-url-3" href="https://blog.csdn.net/qq_45173404/article/details/121687489" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-3">rabbitMQ</span>
                  </div>
                </a>
              </div>
               <div class="col">
                <a id="link-url-3" href="https://www.runoob.com/docker/docker-tutorial.html" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-3">docker</span>
                  </div>
                </a>
              </div>
               <div class="col">
                <a id="link-url-2" href="https://cn.aliyun.com/" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-2">ossEcs</span>
                  </div>
                </a>
              </div>
               <div class="col">
                <a id="link-url-2" href="https://www.w3school.com.cn/index.html" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-2">w3school</span>
                  </div>
                </a>
              </div>
            </div>
          <!--第二组-->
          <div class="row" style="margin-top: 1.5rem;">
                       <div class="col">
                <a id="link-url-1" href="https://baomidou.com/" target="_blank">
                  <div class="link-card cards">
                    <span class="link-name" id="link-name-1">mybatis-plus</span>
                  </div>
                </a>
              </div>
            <div class="col">
              <a id="link-url-4" href="https://element.eleme.cn/#/zh-CN/component/layout" target="_blank">
                <div class="link-card cards">
                  <span class="link-name" id="link-name-4">vue</span>
                </div>
              </a>
            </div>
            <div class="col">
              <a id="link-url-5" href="https://dev-cloud.gitcode.host/spring/guide/spring-cloud/documentation-overview.html#_1-%E5%85%B3%E4%BA%8E%E6%96%87%E6%A1%A3" target="_blank">
                <div class="link-card cards">
                  <span class="link-name" id="link-name-5">springcloud</span>
                </div>
              </a>
            </div>
            <div class="col">
              <a id="link-url-6" href="https://segmentfault.com/a/1190000044304833#item-23" target="_blank">
                <div class="link-card cards">
                  <span class="link-name" id="link-name-6">脚本</span>
                </div>
              </a>
            </div>
            <div class="col">
              <a id="link-url-6" href="https://huaban.com/" target="_blank">
                <div class="link-card cards">
                  <span class="link-name" id="link-name-6">设计</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <br />
        <span slot="footer" class="dialog-footer"></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      input: "",
      path: ""
    };
  },
  methods: {
    setDialogVisible () {
      this.searchFlag = true;
    }
  },
  mounted () {
    this.path = this.$route.path;
  },
  computed: {
    searchFlag: {
      set (value) {
        this.$store.state.searchFlag = value;
      },
      get () {
        return this.$store.state.searchFlag;
      }
    }
  },
  watch: {
    $route (to, from) {
      this.path = to.path;
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap; /* 允许换行，当内容超出容器宽度时 */
  margin-left: -10px; /* 负外边距用于抵消列间的间距 */
}

.col {
  box-sizing: border-box; /* 包含内边距和边框的宽度计算 */
  flex: 0 0 calc(25% - 20px); /* 让列自适应，这里是三等分布局减去左右外边距 */
  max-width: calc(25% - 20px); /* 防止内容溢出 */
  padding: 0 10px; /* 列间间距 */
  margin-bottom: 10px; /* 底部外边距，可根据需要调整 */
}
.link-card {
  height: 70px;
  width: 100%;
  border-radius: 6px;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.link-card i {
  margin-left: 0px;
  font-size: 1rem;
}

.link-card:hover {
  background: rgb(0 0 0 / 40%);
  transition: 0.5s;
}

span.link-name {
  font-size: 1rem;
}

.link-card:hover span.link-name {
  font-size: 1rem;
  transition: 0.1s;
}

i.iconfont.icon-a-daohangzhiyindingwei-05,
i.iconfont.icon-z_shangpinheji {
  font-size: 2rem;
}

.box-card {
  width: 80%;
  margin-left: 9%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.search-input-wrapper {
  display: flex;
  padding: 5px;
  height: 35px;
  width: 100%;
  border: 2px solid #8e8cd8;
  border-radius: 2rem;
}
.custom-dialog >>> .el-dialog__body {
  background-color: rgba(0,0,0,0.5); /* 替换为你想要的背景颜色，例如 #f5f5f5 为浅灰色 */
  border: rgb(8, 255, 243) 1px solid;
}
@media (min-width: 960px) {
  .search-result-wrapper {
    padding-right: 5px;
    height: 450px;
    overflow: auto;
  }
}
@media (max-width: 959px) {
  .search-result-wrapper {
    height: calc(100vh - 110px);
    overflow: auto;
  }
}
.divider {
  margin: 20px 0;
  border: 2px dashed #d2ebfd;
}
.search-reslut-content {
  color: #555;
  cursor: pointer;
  border-bottom: 1px dashed #ccc;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
