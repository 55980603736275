<template>
  <el-footer app padless absolute>
    <div class="footer-wrap">
      <div>&copy; 2024 - {{ new Date().getFullYear() }} By Waffle</div>
      <!-- 备案号 -->
      <a href="http://beian.miit.gov.cn/" target="_blank">
        鄂ICP备2023014094号
      </a>
      <!-- 网站运行页脚 -->
      网站已经安全运行：<span id="htmer_time" style="color: red;"></span>
    </div>
  </el-footer>
</template>
<script>

</script>
<script type="text/javascript" language="javascript">
function secondToDate(second) {
    if (!second) {
            return 0;
        }
        var time = new Array(0, 0, 0, 0, 0);
        if (second >= 365 * 24 * 3600) {
            time[0] = parseInt(second / (365 * 24 * 3600));
            second %= 365 * 24 * 3600;
        }
        if (second >= 24 * 3600) {
            time[1] = parseInt(second / (24 * 3600));
            second %= 24 * 3600;
        }
        if (second >= 3600) {
            time[2] = parseInt(second / 3600);
            second %= 3600;
        }
        if (second >= 60) {
            time[3] = parseInt(second / 60);
            second %= 60;
        }
        if (second > 0) {
            time[4] = second;
        }
        return time;
    }
    function setTime() {
        // 博客创建时间秒数，时间格式中，月比较特殊，是从0开始的，所以想要显示2月，得写1才行，如下
        var create_time = Math.round(new Date(Date.UTC(2023, 11, 1, 0, 0, 0))
                .getTime() / 1000);
        // 当前时间秒数,增加时区的差异
        var timestamp = Math.round((new Date().getTime() + 8 * 60 * 60 * 1000) / 1000);
        currentTime = secondToDate((timestamp - create_time));
        currentTimeHtml = currentTime[0] + '年' + currentTime[1] + '天'
                + currentTime[2] + '时' + currentTime[3] + '分' + currentTime[4]
                + '秒';
        document.getElementById("htmer_time").innerHTML = currentTimeHtml;
    }
    setInterval(setTime, 1000);
</script>

<style scoped>
  .footer-wrap {
    bottom: 0!important;
    width: 100%;
    line-height: 2;
    position: static;
    padding: 40px 20px;
    color: #eee;
    font-size: 14px;
    text-align: center;
   background: linear-gradient(140.54deg, #608D00 0%, #D30000 72.37%), linear-gradient(58.72deg, #0029FF 0%, #8FFF00 100%), radial-gradient(100% 164.72% at 100% 100%, #6100FF 0%, #00FF57 100%), radial-gradient(100% 148.07% at 0% 0%, #FFF500 0%, #51D500 100%);
    background-blend-mode: color-dodge, overlay, difference, normal;
    background-size: 400% 400%;
    animation: Gradient 10s ease infinite;
  }
  .footer-wrap a {
    color: #eee !important;
  }
  @keyframes Gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
</style>
